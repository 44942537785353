@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600;700&display=swap');


//-------------------------
//--- Theme Typography
//-------------------------
$enable-responsive-font-sizes: true;
$font-family: 'Inter', sans-serif;
$font-family-2: 'Cardo', serif;
$font-family-3: 'Barlow', sans-serif;
$font-family-4: 'JetBrains Mono', monospace;
$font-family-5: 'Sora', sans-serif;
$font-family-5: 'Work Sans', sans-serif;
$font-family-6: 'Fira Sans', sans-serif;
$font-family-base: $font-family;

//custom font-family
$btn-font-family: $font-family;
$headings-font-family: $font-family;
$paragraph-font-family: $font-family;

$headings-font-weight: 700;
// $headings-line-height:        1.5;
//- Display Sizes

$display1-size: 6rem; // About 96px
$display2-size: 5rem; // About 80px
$display3-size: 3.75rem; // About 60px
$display4-size: 3.125rem; // About 50px

//- Font Sizes
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: 13px;

$h1-font-size: 4.25rem;
$h2-font-size: 2.625rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.375rem;
$h5-font-size: 1.125rem;
$h6-font-size: 1.125rem;

$text-base: 1rem;
